import React, { useEffect, useState } from "react"
import snackBarSettings from "../../../components/snackBarSettings"
import { withSnackbar } from "react-simple-snackbar"
import { Fade } from "react-reveal"
import { trackPromise } from "react-promise-tracker"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const axios = require(`axios`)

const DoctorCode = props => {
  const intl = useIntl()

  const {
    handleChange,
    initialValue,
    handleCorrectDoctorCode,
    handleRealeseDoctorCode,
  } = props

  const { openSnackbar, closeSnackbar } = props

  const [doctorCode, setDoctorCode] = useState(initialValue)

  const [doctorCodeLocked, setDoctorCodeLocked] = useState(false)

  useEffect(() => {
    if (doctorCode && doctorCode !== "") {
      setDoctorCodeLocked(true)
      handleCorrectDoctorCode(doctorCode)
    }
  }, [])

  const checkDoctorCode = () => {
    trackPromise(
      axios
        .get(
          `${process.env.GATSBY_API_URL}/user/registration/check/doctor-code?doctor_code=${doctorCode}`
        )
        .then(res => {
          if (res.data.valid) {
            setDoctorCodeLocked(true)
            handleCorrectDoctorCode(doctorCode)
          } else {
            openSnackbar(
              intl.formatMessage({
                id: "doctorCode_msgCodeError",
                defaultMessage: "Kod lekarza nieprawidłowy",
              }),
              2500
            )
            realeseDoctorCode()
          }
        })
        .catch(error => {
          if (error.response) {
            openSnackbar(
              intl.formatMessage({
                id: "doctorCode_msgCodeError",
                defaultMessage: "Kod lekarza nieprawidłowy",
              }),
              2500
            )
            realeseDoctorCode()
          }
        })
    )
  }

  const realeseDoctorCode = () => {
    setDoctorCodeLocked(false)
    setDoctorCode("")
    handleRealeseDoctorCode()
  }

  const lockedDoctorCodeContent = (
    <Fade>
      <Box
        display="flex"
        flexWrap="wrap"
        my={5}
        alignItems="center"
        justifyContent="center"
      >
        <Box component="p" m={1}>
          <FormattedMessage
            id="doctorCode_lblYourCode"
            defaultMessage="Twój kod lekarza:"
          />
          <b>{doctorCode}</b>
        </Box>
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={realeseDoctorCode}
          >
            <FormattedMessage
              id="doctorCode_btnUseAnotherCode"
              defaultMessage="Skorzystaj z innego"
            />
          </Button>
        </Box>
      </Box>
    </Fade>
  )

  const emptyDoctorCodeContent = (
    <Box display="flex" flexDirection="column" my={5} alignItems="center">
      <FormattedMessage
        id="doctorCode_lblUserDoctorCode"
        defaultMessage="Posiadasz kod lekarza? Użyj go by uzyskać rabat"
      />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box m={1}>
          <TextField
            name="doctor_code"
            variant="outlined"
            value={doctorCode ? doctorCode : ""}
            onChange={e => setDoctorCode(e.target.value)}
            fullWidth
          />
        </Box>

        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={checkDoctorCode}
          >
            <FormattedMessage
              id="doctorCode_btnUseDoctorCode"
              defaultMessage="Użyj"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )

  if (doctorCodeLocked) {
    return lockedDoctorCodeContent
  } else {
    return emptyDoctorCodeContent
  }
}

export default withSnackbar(DoctorCode, snackBarSettings)
