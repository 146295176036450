import React from "react"
import { useField, useFormikContext, getIn } from "formik"
import "react-datepicker/dist/react-datepicker.css"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import CustomPhone from "./phone"

const PhonePickerField = ({ ...props }) => {
  const {
    setFieldValue,
    handleBlur,
    errors,
    touched,
    values,
  } = useFormikContext()
  const [field] = useField(props)

  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return (
    <>
      <PhoneInput
        international
        {...field}
        {...props}
        inputComponent={CustomPhone}
        error={showError}
        helperText={showError ? fieldError : ""}
        onChange={(phone, country) => setFieldValue(field.name, phone)}
        onBlur={handleBlur}
      />
    </>
  )
}

export default PhonePickerField
