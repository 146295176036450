import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SnackbarProvider from "react-simple-snackbar"
import OrderBanner from "../sections/order/order-banner"
import LoadingIndicator from "../components/LoadingIndicator"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import { useIntl } from "gatsby-plugin-react-intl"

const locale = require(`date-fns/locale/${process.env.GATSBY_DATE_LOCALE_ID}/index.js`)

const OrderPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <LoadingIndicator></LoadingIndicator>
      <SEO
        lang={intl.locale}
        title={data.strapiOrder.seo.title}
        description={data.strapiOrder.seo.description}
        keywords={data.strapiOrder.seo.keywords}
      />
      <SnackbarProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <OrderBanner></OrderBanner>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiOrder {
      seo {
        title
        description
        keywords {
          tag
        }
      }
    }
  }
`

export default OrderPage
