import React from "react"

import { addDays, addMonths, lastDayOfMonth } from "date-fns"

import { Field } from "formik"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { DatePicker } from "formik-material-ui-pickers"
import { TextField } from "formik-material-ui"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const AddressDetailsFormPart = () => {
  const intl = useIntl()

  const disableWeekends = date => {
    return date.getDay() === 0 || date.getDay() === 6
  }

  return (
    <>
      <h4>
        <FormattedMessage
          id="addressDetailsForm_title"
          defaultMessage="Dane do wysyłki"
        />
      </h4>
      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Field
              component={DatePicker}
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldShippingDate",
                defaultMessage: "Data dostawy",
              })}
              helperText={intl.formatMessage({
                id: "addressDetailsForm_hntFieldShippingDate",
                defaultMessage:
                  "Wprowadź jeżeli chcesz opóźnić wysyłkę urządzenia",
              })}
              name="delivery_date"
              format="yyyy-MM-dd"
              disablePast={true}
              minDate={addDays(new Date(), 3)}
              maxDate={lastDayOfMonth(addMonths(new Date(), 3))}
              shouldDisableDate={disableWeekends}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.country"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldCountry",
                defaultMessage: "Kraj",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.voivodeship"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldVoivodeship",
                defaultMessage: "Województwo",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.city"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldCity",
                defaultMessage: "Miasto",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.postal_code"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldPostalCode",
                defaultMessage: "Kod pocztowy",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.street"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldStreet",
                defaultMessage: "Ulica",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Field
              component={TextField}
              name="address_details.house_no"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldHouseNo",
                defaultMessage: "Nr budynku",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Field
              component={TextField}
              name="address_details.apartment_no"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldApartmentNo",
                defaultMessage: "Nr lokalu",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="address_details.additional_info"
              type="text"
              label={intl.formatMessage({
                id: "addressDetailsForm_lblFieldAdditionalInfo",
                defaultMessage: "Dodatkowe informacje",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AddressDetailsFormPart
