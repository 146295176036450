import React, { useState } from "react"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import packageFormSchema from "./packageFormSchema"
import Package from "./package"
import { useOrderDictionary } from "../dictionaries/orderDictionaries"
import DoctorCode from "./doctorCode"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PackageForm = props => {
  const { handleSubmitting, values } = props

  const [doctorCodeDiscount, setDoctorCodeDiscount] = useState(false)

  const { packages } = useOrderDictionary()

  const handleCorrectDoctorCode = doctorCode => {
    values.doctor_code = doctorCode
    setDoctorCodeDiscount(true)
  }

  const handleRealeseDoctorCode = () => {
    values.doctor_code = ""
    setDoctorCodeDiscount(false)
  }

  return (
    <Formik
      validationSchema={packageFormSchema}
      onSubmit={(values, actions) => {
        handleSubmitting(values, packageFormSchema.cast(values))
      }}
      initialValues={values}
    >
      {({ handleChange, handleBlur, isSubmitting, values }) => (
        <Box component={Form} py={3} overflow="hidden">
          <Grid
            container
            wrap="wrap"
            alignItems="stretch"
            justify="center"
            spacing={2}
          >
            {packages.map((element, index) => {
              return (
                <Grid item>
                  <Package
                    name="package_id"
                    packageName={element.name}
                    description={element.description}
                    id={`package${element.package_id}`}
                    price={element.price}
                    discountPrice={element.discount_price}
                    discount={doctorCodeDiscount}
                    currency={element.currency}
                    value={element.package_id}
                    checked={values.package_id == element.package_id}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  ></Package>
                </Grid>
              )
            })}
          </Grid>
          <DoctorCode
            handleChange={handleChange}
            initialValue={values.doctor_code}
            handleCorrectDoctorCode={handleCorrectDoctorCode}
            handleRealeseDoctorCode={handleRealeseDoctorCode}
          ></DoctorCode>
          <Box textAlign="right" mr={3}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="packageForm_btnNext"
                defaultMessage="Dalej"
              />
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

PackageForm.propTypes = {
  handleSubmitting: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default PackageForm
