import React, { useState } from "react"
import Stepper from "react-stepper-horizontal"
import PatientForm from "./patient-form/patientForm"
import ShipmentForm from "./shipment-form/shipmentForm"
import PackageForm from "./package-form/packageForm"
import OrderSummary from "./summary/orderSummary"
import { useOrderDictionary } from "./dictionaries/orderDictionaries"
import { withSnackbar } from "react-simple-snackbar"
import snackBarSettings from "../../components/snackBarSettings"
import { trackPromise } from "react-promise-tracker"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { Hidden } from "@material-ui/core"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const axios = require(`axios`)

const OrderBanner = props => {
  const intl = useIntl()

  const steps = [
    {
      title: intl.formatMessage({
        id: "orderBanner_packageStep",
        defaultMessage: "Twój pakiet",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_shipmentStep",
        defaultMessage: "Dostawa i płatność",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_patientStep",
        defaultMessage: "Twoje dane",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_orderStep",
        defaultMessage: "Zamów",
      }),
    },
  ]

  const { packages, shippingMethods, paymentMethods } = useOrderDictionary()

  const { openSnackbar, closeSnackbar } = props

  const [orderWithDiscount, setOrderWithDiscount] = useState(false)

  const [page, setPage] = useState(0)

  const [packageData, setPackageData] = useState({
    package_id: null,
  })

  const [patientData, setPatientData] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    phone: "",
    birth_date: null,
    language_cd: "",
    environment: {
      estimated_childbirth_date: null,
      past_pregnancies: false,
      weight: "",
      height: "",
      children_no: "",
      medication: "",
      risk_factors: "",
      info: "",
    },
    emergency_person: {
      name: "",
      surname: "",
      email: "",
      kinship: "",
      phone: "",
    },
  })

  const [shipmentData, setShipmentData] = useState({
    shipping_method_id:
      shippingMethods.length > 0 ? shippingMethods[0].shipping_method_id : 1,
    payment_method_id:
      paymentMethods.length > 0 ? paymentMethods[0].payment_method_id : 1,
    delivery_date: null,
    address_details: {
      country: "",
      voivodeship: "",
      city: "",
      postal_code: "",
      street: "",
      house_no: "",
      apartment_no: "",
      additional_info: "",
    },
  })

  const [order, setOrder] = useState({ price: 0 })

  const nextPage = () => {
    setPage(page + 1)
  }

  const handlePackageFormSubmit = (formValues, castValues) => {
    setPackageData(Object.assign(packageData, formValues))
    setOrder(Object.assign(order, castValues))
    setOrderWithDiscount(order.doctor_code && order.doctor_code !== "")

    nextPage()
    window.scrollTo(0, 0)
  }

  const handlePatientFormSubmit = (formValues, castValues) => {
    setPatientData(Object.assign(patientData, formValues))
    setOrder(Object.assign(order, castValues))

    nextPage()
    window.scrollTo(0, 0)
  }

  const handleShipmentFormSubmit = (formValues, castValues) => {
    setShipmentData(Object.assign(shipmentData, formValues))
    setOrder(Object.assign(order, castValues))

    calculateOrderPrice()

    nextPage()
    window.scrollTo(0, 0)
  }

  const calculateOrderPrice = () => {
    const selectedPackageDetails = packages.find(
      p => p.package_id === order.package_id
    )

    const selectedShipmentDetails = shippingMethods.find(
      s => s.shipping_method_id === order.shipping_method_id
    )

    if (orderWithDiscount) {
      order.price = (
        selectedPackageDetails.discount_price + selectedShipmentDetails.price
      ).toFixed(2)
    } else {
      order.price = (
        selectedPackageDetails.price + selectedShipmentDetails.price
      ).toFixed(2)
    }
  }

  const handlePlaceOrder = () => {
    trackPromise(
      axios
        .post(`${process.env.GATSBY_API_URL}/user/registration`, order)
        .then(response => {
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgOrderSuccess",
              defaultMessage:
                "Zamówienie złożone pomyślnie za chwilę nastąpi przekierowanie do płatności...",
            }),
            4000
          )
          setTimeout(() => {
            window.location.href = response.data.payment_url
          }, 2500)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgOrderError",
              defaultMessage: "Nie udało się złożyć zamówienia...",
            }),
            4000
          )
        )
    )
  }

  const previousPage = () => {
    setPage(page - 1)
    window.scrollTo(0, 0)
  }

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={page}
      defaultBorderColor="#e0e0e0"
      activeBorderColor="#f98867"
      completeBorderColor="#f98867"
      defaultColor=""
      activeColor=""
      completeColor=""
      completeBarColor="#f98867"
      activeBarColor="#f98867"
      activeTitleColor="#1b2a65"
      completeTitleColor="#1b2a65"
      defaultBorderStyle="solid"
      completeBorderStyle="solid"
      activeBorderStyle="solid"
      circleFontSize={0}
    />
  )

  const mobileStepper = (
    <Stepper
      steps={steps}
      activeStep={page}
      defaultBorderColor="#e0e0e0"
      activeBorderColor="#f98867"
      completeBorderColor="#f98867"
      defaultColor=""
      activeColor=""
      completeColor=""
      completeBarColor="#f98867"
      activeBarColor="#f98867"
      activeTitleColor="#1b2a65"
      completeTitleColor="#1b2a65"
      defaultBorderStyle="solid"
      completeBorderStyle="solid"
      activeBorderStyle="solid"
      circleFontSize={0}
      titleFontSize={12}
    />
  )

  return (
    <Box
      className="order-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container disableGutters={true}>
        <Box component="h2" textAlign="center">
          <FormattedMessage
            id="orderBanner_title"
            defaultMessage="Zamówienie"
          />
        </Box>
        <Hidden implementation="css" mdDown={true}>
          <div className="order-stepper">{stepper}</div>
        </Hidden>
        <Hidden implementation="css" lgUp={true}>
          <div className="order-stepper d-block d-md-none">{mobileStepper}</div>
        </Hidden>

        {page === 0 && (
          <PackageForm
            handleSubmitting={handlePackageFormSubmit}
            values={packageData}
          ></PackageForm>
        )}
        {page === 1 && (
          <ShipmentForm
            handleSubmitting={handleShipmentFormSubmit}
            handleAbort={previousPage}
            values={shipmentData}
          ></ShipmentForm>
        )}
        {page === 2 && (
          <PatientForm
            handleSubmitting={handlePatientFormSubmit}
            handleAbort={previousPage}
            values={patientData}
          ></PatientForm>
        )}
        {page === 3 && (
          <OrderSummary
            order={order}
            orderDiscount={orderWithDiscount}
            handleAbort={previousPage}
            handleSubmitting={handlePlaceOrder}
          ></OrderSummary>
        )}
      </Container>
    </Box>
  )
}

export default withSnackbar(OrderBanner, snackBarSettings)
