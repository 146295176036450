import * as yup from "yup"

const packageFormSchema = yup.object({
  package_id: yup.number().required(),
  doctor_code: yup
    .string()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable(),
})

export default packageFormSchema
