import React, { useState } from "react"
import PhonePickerField from "../../../components/phonePicker"
import { Eye, EyeSlash } from "react-bootstrap-icons"

import { Field } from "formik"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { DatePicker } from "formik-material-ui-pickers"
import { TextField } from "formik-material-ui"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import { useOrderDictionary } from "../dictionaries/orderDictionaries"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const PersonalDataFormPart = () => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)
  const { languages } = useOrderDictionary()

  const togglePasswordView = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <h4>
        <FormattedMessage
          id="personalDataForm_title"
          defaultMessage="Dane osobowe"
        />
      </h4>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldName",
                defaultMessage: "Imię",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="surname"
              type="text"
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldSurname",
                defaultMessage: "Nazwisko",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="email"
              type="email"
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldEmail",
                defaultMessage: "Email",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="password"
              type={showPassword ? "text" : "password"}
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldPassword",
                defaultMessage: "Hasło",
              })}
              color="secondary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordView}
                      onMouseDown={togglePasswordView}
                      edge="end"
                    >
                      {showPassword ? (
                        <Eye width="1.25rem" height="1.25rem"></Eye>
                      ) : (
                        <EyeSlash width="1.25rem" height="1.25rem"></EyeSlash>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <PhonePickerField
              name="phone"
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldPhone",
                defaultMessage: "Numer telefonu",
              })}
            ></PhonePickerField>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              type="text"
              name="language_cd"
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldLanguage",
                defaultMessage: "Język",
              })}
              select
              variant="standard"
              fullWidth
            >
              <MenuItem value="">
                <FormattedMessage
                  id="personalDataForm_selectLanguage"
                  defaultMessage="Wybierz język..."
                />
              </MenuItem>
              {languages.map(option => (
                <MenuItem key={option.language_cd} value={option.language_cd}>
                  {option.language_name}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Field
              component={DatePicker}
              label={intl.formatMessage({
                id: "personalDataForm_lblFieldBirthDate",
                defaultMessage: "Data urodzenia",
              })}
              name="birth_date"
              format="yyyy-MM-dd"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default PersonalDataFormPart
