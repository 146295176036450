import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useOrderDictionary } from "../dictionaries/orderDictionaries"
import { Calendar, PersonFill, Truck } from "react-bootstrap-icons"
import PackageSummary from "./packageSummary"
import ShipmentSummary from "./shipmentSummary"
import PatientSummary from "./patientSummary"
import CircularButton from "../../../components/circularButton"

import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const OrderSummary = props => {
  const { order, orderDiscount, handleSubmitting, handleAbort } = props
  const { packages } = useOrderDictionary()

  const selectedPackageDetails = useMemo(
    () => packages.find(p => p.package_id === order.package_id),
    [order, packages]
  )

  return (
    <Box my={6}>
      <Card className="order-card">
        <CardContent>
          <Box component="h2" textAlign="center" m={5}>
            <FormattedMessage
              id="orderSummary_title"
              defaultMessage="Podsumowanie"
            />
          </Box>
          <div>
            <h3>
              <Box component="span" mr={3}>
                <Calendar></Calendar>
              </Box>
              <FormattedMessage
                id="orderSummary_lblPackage"
                defaultMessage="Pakiet"
              />
            </h3>
            <PackageSummary
              packageDetails={selectedPackageDetails}
              discount={orderDiscount}
            ></PackageSummary>
          </div>
          <Box my={6}>
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <h3>
                  <Box component="span" mr={3}>
                    <Truck></Truck>
                  </Box>
                  <FormattedMessage
                    id="orderSummary_lblShipment"
                    defaultMessage="Dostawa"
                  />
                </h3>
                <ShipmentSummary shipmentDetails={order}></ShipmentSummary>
              </Grid>
              <Grid item lg={6}>
                <h3>
                  <Box component="span" mr={3}>
                    <PersonFill></PersonFill>
                  </Box>
                  <FormattedMessage
                    id="orderSummary_lblPersonalData"
                    defaultMessage="Dane osobowe"
                  />
                </h3>
                <PatientSummary patientDetails={order}></PatientSummary>
              </Grid>
            </Grid>
            <Box component="p" m={3} textAlign="right">
              <FormattedMessage
                id="orderSummary_summaryPrice"
                defaultMessage="Razem do zapłaty:"
              />{" "}
              <b>{`${order.price} ${selectedPackageDetails.currency}`}</b>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <CircularButton
              onClick={handleAbort}
              direction="left"
            ></CircularButton>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmitting}
            >
              <FormattedMessage
                id="orderSummary_btnOrder"
                defaultMessage="ZAMÓW"
              />
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

OrderSummary.propTypes = {
  handleSubmitting: PropTypes.func,
  handleAbort: PropTypes.func,
  order: PropTypes.object.isRequired,
}

export default OrderSummary
