import React from "react"
import PhonePickerField from "../../../components/phonePicker"
import { Field } from "formik"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { TextField } from "formik-material-ui"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const EmergancyContactFormPart = () => {
  const intl = useIntl()
  return (
    <>
      <h4>
        <FormattedMessage
          id="emergancyContactForm_title"
          defaultMessage="Osoba kontaktowa"
        />
      </h4>
      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="emergency_person.name"
              type="text"
              label={intl.formatMessage({
                id: "emergancyContactForm_lblFieldName",
                defaultMessage: "Imię",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="emergency_person.surname"
              type="text"
              label={intl.formatMessage({
                id: "emergancyContactForm_lblFieldSurname",
                defaultMessage: "Nazwisko",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="emergency_person.email"
              type="email"
              label={intl.formatMessage({
                id: "emergancyContactForm_lblFieldEmail",
                defaultMessage: "Email",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <PhonePickerField
              name="emergency_person.phone"
              label={intl.formatMessage({
                id: "emergancyContactForm_lblFieldPhone",
                defaultMessage: "Numer telefonu",
              })}
            ></PhonePickerField>
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Field
              component={TextField}
              name="emergency_person.kinship"
              type="text"
              label={intl.formatMessage({
                id: "emergancyContactForm_lblFieldKinship",
                defaultMessage: "Pokrewieństwo(kim dla Ciebie jest ta osoba?)",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EmergancyContactFormPart
